@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  font-family: "Encode Sans Expanded", sans-serif;
}

body {
  margin: 0;
}

header {
  position: relative;
  margin-top: 50px;
}

#navbar {
  position: fixed;
  width: 100%;
  height: 50px;
  background-color: #a186cc;
  top: 0;
  z-index: 3;
}

.hamburger {
  display: none;
}

.menu {
  display: flex;
  justify-content: space-between;
  padding: 13px 60px;
  margin: 0;
  list-style-type: none;
  text-align: center;
}
.menu a {
  transition: 0.5s all;
}
.menu a:hover {
  color: #d6bdf8;
}
.menu_link {
  color: #FFF;
  font-size: 15px;
  font-weight: 300;
  text-decoration: none;
}
.menu_item {
  padding-right: 40px;
  border-right: 2px dotted #fff;
}
.menu_item:last-child {
  border-right: none;
}

.subheader {
  position: absolute;
  width: 100%;
  height: 95px;
  background-color: rgba(161, 134, 204, 0.8);
}
.subheader a {
  transition: 0.5s all;
}
.subheader a:hover {
  color: #d6bdf8;
}
.subheader_logo {
  display: block;
  max-width: 100%;
  margin-top: 16px;
  transition: 0.5s all;
}
.subheader_logo img {
  height: 36px;
}
.subheader_logo:hover {
  scale: 1.01;
}
.subheader_official {
  margin-top: 6px;
  opacity: 0.9;
  color: #FFF;
  font-size: 15px;
  line-height: 16px;
  font-weight: 200;
}
.subheader_call {
  margin-top: 20px;
  color: #FFF;
  text-align: right;
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
}
.subheader_phone {
  display: block;
  margin-top: 8px;
  color: #FFF;
  text-align: right;
  font-size: 22px;
  line-height: 22px;
  font-weight: 400;
  text-decoration: none;
}
.subheader_btn {
  margin-top: 18px;
  width: 172px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 4px;
  border: none;
  background-image: linear-gradient(0deg, #a186cc 0%, #d6bdf8 100%);
  box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.12) inset;
}
.subheader_btn:hover {
  background-image: none;
  background-color: #d6bdf8;
}
.subheader_btn .btn_text {
  color: #FFF;
  text-align: center;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.21);
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-transform: uppercase;
}

.partners {
  min-height: 790px;
  background: url(../public/img/bg/main_bg.png) center center/cover no-repeat;
  padding: 150px 0 135px 0;
}
.partners_title {
  margin: 0;
  margin-top: 57px;
  color: #FFF;
  text-align: center;
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  line-height: 48px;
  text-transform: uppercase;
}
.partners_description {
  margin: 0;
  margin-top: 23px;
  color: #FFF;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  text-transform: uppercase;
}
.partners_subtitle {
  margin: 0;
  margin-top: 26px;
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.partners_btn {
  display: block;
  margin: 115px auto 0 auto;
  width: 259px;
  height: 63px;
  flex-shrink: 0;
  border-radius: 4px;
  border: none;
  background-image: linear-gradient(0deg, #a186cc 0%, #d6bdf8 100%);
  box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.12) inset;
  color: #FFF;
  text-align: center;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.21);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}
.partners_btn:hover {
  background-image: none;
  background-color: #d6bdf8;
}

.reasons {
  min-height: 849px;
  padding: 20px 0 64px 0;
  background-color: #FFF;
}

.label {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 113px;
  height: 24px;
  flex-shrink: 0;
  border: none;
  background-color: #000;
  color: #FFF;
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  z-index: 2;
}
.label_white {
  background: #FFF;
  color: #070716;
}

.title {
  position: relative;
  margin: 43px 0 0 0;
  color: #222;
  text-align: center;
  font-size: 38px;
  line-height: 38px;
  font-weight: 700;
  text-transform: uppercase;
}
.title_white {
  color: #FFF;
}

.subtitle {
  position: relative;
  margin-top: 18px;
  color: #222;
  text-align: center;
  font-size: 17px;
  line-height: 17px;
  font-style: normal;
  font-weight: 400;
}
.subtitle_white {
  color: #FFF;
}

.reasons .advantage_block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  max-height: 150px;
}
.reasons .payment_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 26px;
  width: 113px;
  height: 113px;
  background-color: #d6bdf8;
  border-radius: 100%;
}
.reasons .advantage_descr {
  color: #212121;
  font-size: 15px;
  font-weight: 300;
  line-height: 21px;
  width: 343px;
}
.reasons .advantage_descr span {
  color: #d6bdf8;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  width: 343px;
}

.mobile {
  min-height: 864px;
  padding: 48px 0 79px 0;
  background: url(../public/img/bg/mobile_bg.png) center center/cover no-repeat;
}
.mobile .row {
  margin-top: 30px;
}
.mobile_item {
  position: relative;
  width: 320px;
  height: 280px;
  opacity: 0.9;
  flex-shrink: 0;
  border-radius: 5px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding-top: 85px;
}
.mobile_item_1 {
  background: url(../public/img/mobile_bg/card_1.jpg) center center/cover no-repeat;
}
.mobile_item_2 {
  background: url(../public/img/mobile_bg/card_2.jpg) center center/cover no-repeat;
}
.mobile_item_3 {
  background: url(../public/img/mobile_bg/card_3.jpg) center center/cover no-repeat;
}
.mobile_item_4 {
  background: url(../public/img/mobile_bg/card_4.jpg) center center/cover no-repeat;
}
.mobile_item_5 {
  background: url(../public/img/mobile_bg/card_5.jpg) center center/cover no-repeat;
}
.mobile_item_6 {
  background: url(../public/img/mobile_bg/card_6.jpg) center center/cover no-repeat;
}
.mobile_item_subtitle {
  color: #FFF;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  text-transform: uppercase;
}
.mobile_item_plus {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 35px;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  width: 25px;
  height: 25px;
  margin: 0 auto;
  flex-shrink: 0;
  border-radius: 100%;
  background-color: #000;
  padding: 6px;
  cursor: pointer;
  transition: 0.5s all;
}
.mobile_item_plus:before {
  content: "";
  display: block;
  width: 14px;
  height: 2px;
  background-color: #d6bdf8;
  transition: 0.5s all;
}
.mobile_item_plus:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 2px;
  height: 14px;
  background-color: #d6bdf8;
  transition: 0.5s all;
}
.mobile_item_plus:hover:before, .mobile_item_plus:hover:after {
  background-color: #FF5656;
}

.choise {
  position: relative;
  padding: 59px 0 137px 0;
  min-height: 898px;
  background: url(../public/img/bg/car_bg.png) center center/auto 50% repeat;
}
.choise .black {
  position: absolute;
  top: 0;
  left: 0;
  height: 502px;
  width: 100%;
  background-color: #212121;
}
.choise_car {
  position: relative;
  display: block;
  margin: 56px auto 0 auto;
}
.choise_description {
  color: #222;
  text-align: center;
  font-size: 17px;
  font-weight: 300;
  line-height: 24px;
}

.terms {
  position: relative;
  padding: 68px 0 90px 0;
}
.terms_block {
  margin: 33px 0;
}
.terms_block_nmb {
  margin-bottom: 0;
}
.terms_title {
  margin-bottom: 22px;
  color: #1CA8C3;
  font-size: 22px;
  font-weight: 400;
  line-height: 22px;
}
.terms_title_red {
  color: #FF5656;
}
.terms_list {
  list-style-type: none;
  padding-left: 50px;
  margin-top: 22px;
}
.terms_list li {
  position: relative;
  color: #222;
  font-size: 17px;
  font-weight: 300;
  line-height: 17px;
  margin-bottom: 16px;
}
.terms_list li:before {
  content: "";
  display: block;
  position: absolute;
  left: -32px;
  top: 6px;
  transform: translateY(-50%);
  width: 21px;
  height: 20px;
  background: url("../public/img/terms_icon/blue.png") center center/cover no-repeat;
}
.terms_profit {
  color: #1CA8C3;
  font-size: 17px;
  font-weight: 300;
  line-height: 24px;
}
.terms .red .terms_title {
  color: #FF5656;
}
.terms .red .terms_list li::before {
  background: url("../public/img/terms_icon/red.png") center center/cover no-repeat;
}

.world {
  position: relative;
  padding: 50px 0 50px 0;
  min-height: 720px;
  background: url("../public/img/bg/world_bg.png") center center/auto 100% no-repeat;
  background-color: #080810;
}
.world_link {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 49px;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 68px;
  text-transform: uppercase;
  color: #d6bdf8;
  text-decoration: none;
  transition: 0.5s all;
}
.world_link:hover {
  color: #fff;
}

footer {
  min-height: 155px;
  padding: 14px 25px 40px 22px;
}
footer a {
  transition: 0.5s all;
}
footer a:hover {
  color: #d6bdf8;
}

.footer_divider {
  margin: 0 auto;
  width: 300px;
  height: 4px;
  background-color: #d6bdf8;
}
.footer_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 32px;
}
.footer_wrapper > div {
  width: 33%;
}
.footer_social {
  width: 154px;
  display: flex;
  justify-content: space-between;
}
.footer_social a {
  text-decoration: none;
}
.footer_social_item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: #212121;
  flex-shrink: 0;
}
.footer_social_item svg {
  color: #FFF;
}
.footer_links {
  width: 390px;
  margin: 0 auto;
}
.footer_links_main {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.footer_links_main a {
  text-decoration: none;
  color: #212121;
  font-size: 15px;
  font-weight: 300;
  line-height: 15px;
  text-transform: uppercase;
}
.footer_links_sub {
  display: flex;
  justify-content: space-between;
}
.footer_links_sub a {
  text-decoration: none;
  color: #999;
  margin-top: 18px;
  font-size: 11px;
  font-weight: 300;
  line-height: 11px;
  text-transform: uppercase;
}
.footer_links_lang {
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: #d6bdf8;
  margin-top: 18px;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 11px;
  text-transform: uppercase;
  transition: 0.5s all;
}
.footer_links_lang:hover {
  color: #212121;
}
.footer_mobile {
  display: flex;
  justify-content: flex-end;
}
.footer_mobile img {
  height: 35px;
  padding-left: 15px;
}
.footer_mobile a {
  text-decoration: none;
}

.xs-visible {
  display: none;
}

@media (max-width: 1200px) {
  .menu_item {
    padding-right: 20px;
  }
  .menu_link {
    font-size: 13px;
  }
  .partners_title {
    font-size: 50px;
  }
  .partners_description {
    font-size: 30px;
  }
  .reasons .advantage_block {
    align-items: flex-start;
    max-height: none;
  }
  .reasons .advantage_descr {
    width: 300px;
  }
  .mobile_item {
    width: 290px;
  }
  .world {
    min-height: 600px;
  }
  footer {
    padding: 14px 25px 14px 22px;
    min-height: 130px;
  }
  .footer_links {
    width: 390px;
  }
}
@media (min-width: 1015px) and (max-width: 1090px) {
  .footer_links {
    width: 350px;
  }
}
@media (min-width: 992px) and (max-width: 1014px) {
  .footer_links {
    width: 330px;
  }
}
@media (max-width: 991px) {
  .menu_item {
    padding-right: 9px;
  }
  .menu_link {
    font-size: 11px;
  }
  .subheader_official {
    font-size: 12px;
  }
  .subheader_phone {
    font-size: 18px;
  }
  .subheader_btn {
    margin-top: 22px;
    width: 95%;
    height: 40px;
  }
  .partners {
    padding: 70px 0 100px 0;
    min-height: auto;
  }
  .partners_title {
    font-size: 40px;
    line-height: 40px;
    margin-top: 40px;
  }
  .partners_description {
    margin-top: 25px;
    font-size: 25px;
    line-height: 25px;
  }
  .partners_subtitle {
    font-size: 18px;
    line-height: 19px;
  }
  .partners_btn {
    margin-top: 80px;
    width: 200px;
    height: 50px;
  }
  .reasons .advantage_block {
    flex-direction: column;
    align-items: center;
  }
  .reasons .payment_circle {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .reasons .advantage_descr {
    width: 100%;
  }
  .choise {
    min-height: auto;
    padding: 59px 0 50px 0;
  }
  .terms {
    padding-bottom: 50px;
  }
  .terms_block {
    margin: 33px 0 33px 25px;
  }
  .world {
    min-height: 450px;
  }
  .mobile {
    min-height: 800px;
    padding-bottom: 48px;
  }
  .mobile_item {
    width: 220px;
    height: 250px;
  }
  .mobile_item_subtitle {
    font-size: 20px;
    line-height: 28px;
  }
  .world {
    min-height: 400px;
  }
  .title {
    font-size: 34px;
  }
  .choise img {
    width: 100%;
  }
  .footer_social_item {
    width: 30px;
    height: 30px;
  }
  .footer_links {
    width: 260px;
  }
  .footer_links_main a {
    font-size: 11px;
  }
  .footer_links_sub a {
    font-size: 8px;
  }
  .footer_mobile {
    text-align: center;
  }
  .footer_mobile img {
    width: 25%;
  }
  .footer_wrapper {
    flex-wrap: wrap;
  }
  .footer_wrapper > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .footer_wrapper > div:nth-child(1) {
    width: 100%;
  }
  .footer_wrapper > div:nth-child(3) {
    width: 100%;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .title {
    margin-top: 25px;
  }
  .menu {
    position: fixed;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: -100%;
    width: 50%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    transition: 0.6s all;
  }
  .menu_active {
    left: 0;
  }
  .menu_item {
    padding-right: 0;
    border: none;
    margin: 10px 0;
  }
  .menu_link {
    font-size: 20px;
  }
  .hamburger {
    display: block;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 26px;
    height: 12px;
  }
  .hamburger span {
    display: block;
    height: 2px;
    width: 100%;
    background-color: #fff;
    margin-bottom: 2px;
    transition: 0.5s all;
  }
  .hamburger_active span {
    margin-bottom: -2px;
  }
  .hamburger_active span:nth-child(1) {
    transform: translateY(3px) rotate(-45deg);
  }
  .hamburger_active span:nth-child(2) {
    display: none;
  }
  .hamburger_active span:nth-child(3) {
    transform: translateY(3px) rotate(45deg);
  }
  .xs-hidden {
    display: none;
  }
  .xs-visible {
    display: block;
  }
  .subheader_phone {
    display: block;
    margin-top: 10px;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
  }
  .subheader_btn {
    display: block;
    margin: 0 auto;
    width: 200px;
    height: 40px;
    flex-shrink: 0;
  }
  .partners {
    padding: 70px 0 100px 0;
    min-height: auto;
  }
  .partners_title {
    font-size: 30px;
    line-height: 30px;
    margin-top: 50px;
  }
  .partners_description {
    margin-top: 20px;
    font-size: 20px;
    line-height: 20px;
  }
  .partners_subtitle {
    font-size: 15px;
    line-height: 16px;
  }
  .partners_btn {
    margin-top: 50px;
    width: 195px;
    height: 40px;
  }
}
@media (max-width: 575px) {
  #navbar {
    height: 30px;
  }
  header {
    height: 30px;
    margin-top: 0;
  }
  .subheader {
    top: 30px;
    height: 60px;
  }
  .subheader_official {
    display: none;
  }
  .subheader_logo {
    margin-top: 16px;
  }
  .subheader_phone {
    margin-top: 11px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .subheader_btn {
    margin-top: 7px;
    width: 110px;
    height: 22px;
  }
  .subheader_btn .btn_text {
    font-size: 10px;
  }
  .partners {
    min-height: 400px;
    padding: 93px 0 35px 0;
  }
  .partners_title {
    color: #FFF;
    text-align: center;
    font-size: 30px;
    font-weight: 900;
    line-height: 35px;
    text-transform: uppercase;
    padding: 0 20px;
  }
  .partners_description {
    color: #FFF;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    padding: 0 10px;
  }
  .partners_subtitle {
    display: none;
  }
  .partners_btn {
    width: 210px;
    height: 50px;
    flex-shrink: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .menu {
    width: 85%;
  }
  .reasons {
    padding: 22px 0 36px 0;
  }
  .reasons .advantage_block {
    margin-top: 26px;
  }
  .reasons .advantage_block .payment_circle {
    width: 100px;
    height: 100px;
  }
  .reasons .advantage_descr {
    margin-top: 27px;
  }
  .reasons .advantage_descr span {
    font-size: 16px;
    text-align: center;
  }
  .title {
    font-size: 16px;
    margin-top: 15px;
    line-height: 16px;
  }
  .world {
    min-height: 360px;
    padding: 25px 0 30px 0;
  }
  .world_link {
    width: 200px;
    display: block;
    bottom: 30px;
    line-height: 15px;
  }
  footer {
    padding: 25px 0 20px 0;
  }
  .footer_divider {
    width: 253px;
    margin: 0 auto;
  }
  .footer_wrapper {
    padding-top: 22px;
  }
  .footer_wrapper > div {
    margin-bottom: 20px;
  }
  .footer_wrapper > div:nth-child(1) {
    order: 2;
  }
  .footer_wrapper > div:nth-child(2) {
    order: 1;
  }
  .footer_wrapper > div:nth-child(3) {
    order: 3;
  }
  .footer_links {
    width: 290px;
  }
  .footer_links_main {
    padding: 0;
    font-size: 14px;
  }
  .footer_links_sub {
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer_links_sub a {
    margin: 0 7px;
  }
  .footer_lang {
    margin-top: 10px;
  }
}
